import React, { Fragment } from 'react';
import LinkButton from '../components/Buttons/LinkButton/LinkButton';
import Hero from '../components/Hero/Hero';
import Seo from '../components/Seo/Seo';

const FourOhFourPage = (): JSX.Element => (
    <Fragment>
        <Seo title="404" />
        <Hero height="xLarge">
            <Hero.Body containerWidth="small">
                <div className="text-center w-full space-y-2">
                    <h1 className="gold text-title">404 - Page not found</h1>
                    <p className="text-paragraph">Whoops! That page doesn't exist</p>
                    <LinkButton href="/" label="Back to home" />
                </div>
            </Hero.Body>
        </Hero>
    </Fragment>
);

export default FourOhFourPage;
